import React, { useState } from "react";
import { useSprings, animated, to as interpolate } from "react-spring";
import { useDrag } from "react-use-gesture";
import styles from "./cardstack.module.css";
import Image from "gatsby-image";
import { shuffleArray } from "./coldCallUtils";

// Inspired by: https://codesandbox.io/s/cards-forked-fqdg5?file=/package.json:305-315

// These two are just helpers, they curate spring data, values that are later being interpolated into css
const to = (i: number) => ({
  x: 0,
  y: i * -4,
  scale: 1,
  rot: -10 + Math.random() * 20,
  delay: i * 20,
});
const from = () => ({ x: 0, rot: 0, scale: 1.5, y: 100 });
// This is being used down there in the view, it interpolates rotation and scale into a css transform
const trans = (r: number, s: number) =>
  `perspective(1500px) rotateX(30deg) rotateY(${
    r / 10
  }deg) rotateZ(${r}deg) scale(${s})`;

const Deck = ({ students, cardLogo }) => {
  const [shuffled, setShuffled] = useState([
    ...shuffleArray(students),
    { name: "", img: cardLogo },
  ]);
  const [gone] = useState(() => new Set()); // The set flags all the cards that are flicked out
  const [props, set] = useSprings(shuffled.length, i => ({
    ...to(i),
    from: from(),
  })); // Create a bunch of springs using the helpers above
  const bind = useDrag(
    ({ args: [index], down, movement: [mx], direction: [xDir], velocity }) => {
      const trigger = velocity > 0.2; // If you flick hard enough it should trigger the card to fly out
      const dir = xDir < 0 ? -1 : 1; // Direction should either point left or right
      if (!down && trigger) gone.add(index); // If button/finger's up and trigger velocity is reached, we flag the card ready to fly out
      set(i => {
        if (index !== i) return; // We're only interested in changing spring-data for the current spring
        const isGone = gone.has(index);
        const x = isGone ? (200 + window.innerWidth) * dir : down ? mx : 0; // When a card is gone it flys out left or right, otherwise goes back to zero
        const rot = mx / 100 + (isGone ? dir * 10 * velocity : 0); // How much the card tilts, flicking it harder makes it rotate faster
        const scale = down ? 1.1 : 1; // Active cards lift up a bit
        return {
          x,
          rot,
          scale,
          delay: undefined,
          config: { friction: 50, tension: down ? 800 : isGone ? 200 : 500 },
        };
      });
      if (!down && gone.size === shuffled.length) {
        setTimeout(() => {
          gone.clear();
          set(i => to(i));
        }, 200);
      }
    }
  );
  // Now we're just mapping the animated values to our view, that's it. Btw, this component only renders once. :-)
  return (
    <div className={styles.deckcontainer}>
      {props.map(({ x, y, rot, scale }, i) => {
        const student = shuffled[i];
        return (
          <animated.div key={i} style={{ x, y }}>
            {/* This is the card itself, we're binding our gesture to it (and inject its index so we know which is which) */}
            <animated.div
              {...bind(i)}
              style={{
                transform: interpolate([rot, scale], trans),
              }}
            >
              {student.name && (
                <h3 className={styles.cardtext}>{student.name}</h3>
              )}
              {student.img && (
                <Image
                  className={styles.cardimage}
                  fluid={cardLogo}
                  alt="card logo"
                />
              )}
            </animated.div>
          </animated.div>
        );
      })}
      <button
        onClick={() => {
          gone.clear();
          setShuffled([...shuffleArray(students), { name: "", img: cardLogo }]);
          set(i => to(i));
        }}
      >
        Shuffle
      </button>
    </div>
  );
};

export default Deck;
